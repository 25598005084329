@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

html {
  font-family: "Lato", sans-serif;
  color: #fafafa;
  background-color: #222222;
  overflow-x: hidden;
}

a {
  text-decoration: underline;
}

/* -------------------------------- LANDING ------------------------------------------- */
/* HEADER */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.header-logo {
  padding: 1rem;
  transition: all 0.15s ease-in-out;
  & > img {
    width: 4rem;
  }
}

.header-logo:hover {
  filter: drop-shadow(0rem 0rem 0.15rem #fafafa);
}

.subtitle {
  font-weight: 300;
}
/* END HEADER */

/* MAIN */
main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.25rem;
  padding: 1rem;
}

.underline {
  text-decoration: underline;
  padding: 2rem;
}

.text-center {
  text-align: center;
}
/* Animation menu */

:root {
  --transition-duration: 0.3s;
  --transition-delay: 0.2s;
}

a {
  display: flex;
  justify-content: center;
  color: inherit;
  text-decoration: none;
}

hr {
  border-color: var(--hr-color);
  margin: 20px 0;
}

.menu {
  display: flex;
  justify-content: center;
  padding: 1rem;
  user-select: none;
  text-decoration: underline;
}

.menu a {
  position: relative;
  display: block;
  padding: 10px;
}

.menu .border {
  position: absolute;
  left: 0;
  background: currentColor;
  transition: transform var(--transition-duration) ease-in-out;
}

.menu .border-top,
.menu .border-bottom {
  width: 100%;
  height: 1px;
  transform: scaleX(0);
}

.menu .border-left,
.menu .border-right {
  width: 1px;
  height: 100%;
  transform: scaleY(0);
}

.menu .border-top,
.menu .border-left,
.menu .border-right {
  top: 0;
}

.menu .border-bottom {
  bottom: 0;
  transform-origin: bottom right;
}

.menu .border-top {
  transform-origin: top left;
}

.menu .border-left {
  transform-origin: bottom left;
}

.menu .border-right {
  left: auto;
  right: 0;
  transform-origin: top right;
}

.menu a:hover .border-top,
.menu a:hover .border-bottom {
  transform: scaleX(1);
}

.menu a:hover .border-left,
.menu a:hover .border-right {
  transform: scaleY(1);
}

.menu a:hover .border-right {
  transition-delay: var(--transition-delay);
}

.menu a:hover .border-bottom {
  transition-delay: calc(var(--transition-delay) * 2);
}

.menu a:hover .border-left {
  transition-delay: calc(var(--transition-delay) * 3);
}

/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menu[data-animation="diagonal"] .border-left {
  transform-origin: top left;
}

.menu[data-animation="diagonal"] .border-right,
.menu[data-animation="diagonal"] .border-bottom {
  transform-origin: bottom right;
}

.menu[data-animation="diagonal"] a:hover [class^="border"] {
  transition-delay: 0s;
}

.container-items {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.brand {
  fill: #fff;
  padding: 1rem;
  width: 4rem;
  transition: all 0.15s ease-in-out;
  &:hover {
    filter: drop-shadow(0rem 0rem 0.15rem #fafafa);
  }
}

.download {
  transition: all 0.15s ease-in-out;
  &:hover {
    filter: drop-shadow(0rem 0rem 0.15rem #fafafa);
  }
}

/* FIN MAIN */

/* --------------------------- END LANDING ------------------------------ */

/* --------------------------- CV ------------------------------ */
#VGL {
  position: fixed;
  bottom: 0;
  width: 15rem;
}

@media screen and (width < 768px) {
  #VGL {
    width: 6rem;
  }
}

.slide-in {
  -webkit-animation: slide-in 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateY(300px) scale(-0.25) rotate(-45deg);
    transform: translateY(300px) scale(-0.25) rotate(-45deg);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateY(0) scale(1) rotate(0deg);
    transform: translateY(0) scale(1) rotate(0deg);
    opacity: 1;
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateY(300px) scale(-0.25) rotate(-45deg);
    transform: translateY(300px) scale(-0.25) rotate(-45deg);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateY(0) scale(1) rotate(0deg);
    transform: translateY(0) scale(1) rotate(0deg);
    opacity: 1;
  }
}

.df {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-4 {
  padding: 0.5rem 0;
}

.study-content {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.study-content-card {
  padding: 0.5rem;
  text-align: center;
}

.job-content {
  display: flex;
  flex-direction: column;
}

.job-content-card {
  text-align: center;
  padding: 1rem;
  max-width: 500px;
}

ul {
  list-style-type: none;
  columns: 2;
  gap: 2rem;
  -webkit-columns: 2;
  -moz-columns: 2;
}

/* -------------------------------- END CV ------------------------------------------- */

/* -------------------------------- BLOG ------------------------------------------- */

.entry {
  text-align: center;
  padding: 2rem;
  max-width: 500px;
}

.entry-date {
  text-align: center;
}

.entry-content-card {
  text-align: center;
  padding: 1rem;
}

.entry-content-img {
  width: 15rem;
  border-radius: 1rem;
}

.container-vertical-line {
  margin: 1rem;
  display: flex;
  justify-content: center;
}

.vertical-line {
  width: 0.1rem;
  background-color: #dddddd;
  height: 100%;
  float: left;
}

.container-vertical-dotted-line {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vertical-dotted-line {
  width: 0.3rem;
  background-color: #555;
  height: 100%;
  float: left;
}

/* -------------------------------- END BLOG ------------------------------------------- */

/* -------------------------------- CURSOR ------------------------------------------- */

* {
  cursor: none;
}

.hover-this {
  transition: all 0.3s ease;
  cursor: pointer !important;
  & * {
    cursor: pointer !important;
  }
}

@media screen and (width > 768px) {
  .cursor {
    pointer-events: none;
    position: fixed;
    padding: 0.3rem;
    background-color: #fff;
    border-radius: 50%;
    mix-blend-mode: difference;
    transition: transform 0.3s ease;
  }
}
