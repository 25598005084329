.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  max-width: 1024px;
  margin: auto;
  height: 100%;
  overflow-x: hidden;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  font-weight: 300;
  background-repeat: no-repeat;
  background-size: cover;
}
